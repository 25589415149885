.header {
  position: absolute;
  z-index: 20;
  width: 100%;
  background-color: #0433a1;

  &__container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__hamburger {
    display: block;
    width: 24px;
    // margin-left: 15px;
    cursor: pointer;

    span {
      display: block;
      height: 2px;
      margin-bottom: 5px;
      border-radius: 15px;
      background-color: #fff;
      transition: transform 0.4s ease, opacity 0.2s ease;

      &:first-of-type {
        transform-origin: top right;
      }

      &:last-of-type {
        margin-bottom: 0;
        transform-origin: bottom right;
      }
    }

    &.open {
      span {
        &:first-of-type {
          transform: rotate(-45deg) translateY(-2px);
          top: -1px;
          // margin-bottom: 9px;
        }

        &:nth-of-type(2) {
          transform: translateX(100%);
          opacity: 0;
        }

        &:last-of-type {
          transform: rotate(45deg) translateY(2px);
          bottom: -1px;
        }
      }
    }
  }

  &__dropdown {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 81px;
    background: #fff;
    z-index: 50;
    display: none;
    &.open {
      display: block;
    }

    &__link {
      $link: &;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid rgb(229, 231, 235);
      display: block;
      font-size: 1.125rem;
      line-height: 1.75rem;
      padding-left: 2rem;
      text-decoration: none;
      color: #6b7280;

      &__text {
        border-bottom: 1px solid rgb(229, 231, 235);
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
      }

      &__items {
        margin-top: 1rem;
        margin-left: 2rem;
        display: none;
        a {
          padding-top: 1rem;
          padding-bottom: 1rem;
          display: block;
          font-size: 1.125rem;
          line-height: 1.75rem;
          padding-left: 2rem;
          text-decoration: none;
          color: #6b7280;
        }
      }

      &.parent-link {
        border-bottom: none;
        padding: 0;
        &:hover {
          #{$link} {
            &__items {
              display: block;
            }
          }
        }
      }
    }
  }
}
