.sharing-panel {
  height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(100%);
  transition: 250ms ease transform;
  pointer-events: none;
  padding-top: 50px;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    position: relative;
    z-index: 21;
    max-width: 620px;
    width: 100%;
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    color: #fff;
    font-size: 55px;
    margin: 30px 0 20px;
  }

  &__share {
    padding: 30px;
    background: #fff;
    width: 100%;
  }

  &__close {
    cursor: pointer;
    margin-left: auto;
    width: max-content;
    pointer-events: all;
  }

  &__icons {
    display: flex;
    align-items: center;
  }

  &__btn {
    display: block;
    margin-right: 25px;
    cursor: pointer;
    pointer-events: all;
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
      border-radius: 50%;
    }
  }

  &__label-text {
    margin-top: 35px;
    margin-bottom: 15px;
    font-weight: 400;
    line-height: 1.4;
    pointer-events: all;

    a {
      color: #565c84;
    }
  }

  &__label {
    // margin-top: 50px;
    color: #565c84;
    margin-bottom: 15px;
    font-weight: 500;
  }

  &__input-holder {
    width: 100%;
    position: relative;
    pointer-events: all;
    input {
      padding: 15px;
      border-radius: 4px;
      border: 1px solid #ccc;
      width: 100%;
      background: #fff;
    }

    button {
      position: absolute;
      border: none;
      background: #fff;
      padding: 15px;
      color: #4575c4;
      top: 50%;
      transform: translateY(-50%);
      right: 2px;
      font-weight: 700;
      cursor: pointer;

      &.copied {
        color: green;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .sharing-panel {
    &__title {
      font-size: 30px;
    }

    &__content {
      max-width: 95%;
    }

    &__share{
      padding: 20px;
    }

    &__btn {
      margin-right: 15px;
      img {
        width: 40px;
      }
    }

    &__label-text {
      margin-top: 20px;
      font-size: 15px;
    }

    &__label {
      font-size: 15px;
    }
  }
}
