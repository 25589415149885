.hero {
  height: 100vh;
  position: relative;
  width: 100%;
  position: fixed;
  z-index: 9;
  cursor: pointer;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    padding-top: 120px;
    position: relative;
    z-index: 1;
    max-width: 900px;
    width: 100%;
    max-width: 95%;
    margin: 0 auto;
    padding: 40px 20px;
    padding-top: 140px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

  &__text-wrapper {
    display: flex;
    justify-content: center;
  }

  &__text {
    max-width: 150px;
    line-height: 1.2;
    color: #fff;
    font-size: 25px;
    position: absolute;
    // bottom: 120px;
    bottom: 20px;
    margin: 0 auto;
    text-align: center;
  }

  &__title {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }

  &__button {
    background: #fff;
    border-radius: 4px;
    color: #14329B;
    padding: 18px 50px;
    width: max-content;
    margin: 50px auto;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .hero {
    &__content {
      padding-top: 100px;
    }

    &__text {
      font-size: 18px;
      // bottom: 100px;
    }
  }
}
