.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f3f4f6;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  opacity: 0;
  transition: 500ms ease opacity;

  &.active {
    opacity: 1;
  }

  &__icons-wrapper {
    position: relative;
    width: 115px;
    height: 115px;
  }

  &__circle {
    width: 100%;
    height: 100%;
    border: 2px solid #005ca4;
    // border-top: 2px solid #edf6f8;
    border-top: 2px solid #f3f4f6;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    -webkit-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
  }

  &__progress-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #0433a1;
    font-weight: 500;
    margin-top: 90px;
    width: 150px;
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotating {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
