.popup {
  position: fixed;
  background-color: #fff;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
  transition: 250ms z-index ease, 250ms opacity ease;
  overflow-y: scroll;

  &.opened {
    opacity: 1;
    z-index: 30;
  }

  &__close {
    cursor: pointer;
    right: 20px;
    top: 20px;
    position: absolute;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;

    padding: 100px 20px;
    // max-width: 550px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    gap: 100px;

    &__text {
      margin-top: 30px;
      line-height: 1.4;
      color: #333;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        color: #999;
        left: -52px;
        top: -50px;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='21' height='21' fill='white'/%3E%3Cpath d='M14.0186 21C14.019 17 13.4186 8.2 19.0186 3' stroke='%23A9A9A9' stroke-width='5'/%3E%3Cpath d='M14.0186 21C14.019 17 13.4186 8.2 19.0186 3' stroke='%23A9A9A9' stroke-width='5'/%3E%3Cpath d='M14.0186 21C14.019 17 13.4186 8.2 19.0186 3' stroke='%23A9A9A9' stroke-width='5'/%3E%3Cpath d='M14.0186 21C14.019 17 13.4186 8.2 19.0186 3' stroke='%23A9A9A9' stroke-width='5'/%3E%3Cpath d='M14.0186 21C14.019 17 13.4186 8.2 19.0186 3' stroke='%23A9A9A9' stroke-width='5'/%3E%3Cpath d='M3.01861 21C3.01904 17 2.41861 8.2 8.01861 3' stroke='%23A9A9A9' stroke-width='5'/%3E%3Cpath d='M3.01861 21C3.01904 17 2.41861 8.2 8.01861 3' stroke='%23A9A9A9' stroke-width='5'/%3E%3Cpath d='M3.01861 21C3.01904 17 2.41861 8.2 8.01861 3' stroke='%23A9A9A9' stroke-width='5'/%3E%3Cpath d='M3.01861 21C3.01904 17 2.41861 8.2 8.01861 3' stroke='%23A9A9A9' stroke-width='5'/%3E%3Cpath d='M3.01861 21C3.01904 17 2.41861 8.2 8.01861 3' stroke='%23A9A9A9' stroke-width='5'/%3E%3C/svg%3E%0A");
        width: 43px;
        height: 98px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__image,
  video {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }

  video {
    width: 100%;
    max-width: 640px;
  }

  &__author {
    text-align: right;
    margin-top: 20px;
    color: #333;
  }

  &__button {
    background: #0433a1;
    border-radius: 8px;
    color: #fff;
    padding: 14px 20px;
    width: max-content;
    margin: 50px auto;
    cursor: pointer;
  }
}

@media screen and (max-width: 1280px) {
  .popup {
    &__content {
      display: block;
      max-width: 550px;
      height: auto;
      padding-bottom: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .popup {
    padding: 0;

    &__content {
      padding-left: 0;
      padding-right: 0;
    }

    &__content-container {
      padding: 0 65px;
    }
  }
}
