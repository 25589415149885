@font-face {
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("./assets/fonts/BentonSans-ExtraLight.otf") format("opentype");
}

@font-face {
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./assets/fonts/BentonSans-Light.otf") format("opentype");
}

@font-face {
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/BentonSans-Book.otf") format("opentype");
}

@font-face {
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./assets/fonts/BentonSans-Black.otf") format("opentype");
}

* {
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
}

html,
body,
#root,
#main {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  user-select: none;
}

canvas {
  touch-action: none;
}

.sound-on {
  position: fixed;
  bottom: 350px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 300px;
  opacity: 0;
  pointer-events: none;
}

.scroll-icon,
.scroll-icon-2 {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 150px;
  height: auto;
  pointer-events: none;
  animation: infinite moveScroll 1.2s;
  opacity: 1;
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;

  &.hidden {
    opacity: 0;
    transform: translateX(-50%) translateY(-20px);
  }
}

.scroll-icon-2 {
  opacity: 0;
}

@keyframes moveScroll {
  0% {
    bottom: 20px;
  }
  50% {
    bottom: 30px;
  }
  100% {
    bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .scroll-icon {
    width: 100px;
  }

  .sound-on {
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 70%;
  }
}
